import { localIdleRouteResponseContentType, idleEquipmentRouteResponseType, localIdleRouteType, CoordinatesType } from "./types";
import { formatAsLocalDateTime, getLocation } from "../../../helpers";
import Box from "@mui/material/Box";
import React from "react";


export const localIdleToRow = (route: localIdleRouteResponseContentType): localIdleRouteType => {

	const { broker, equipmentType, id, loadId, deliveryDate, distance, deliveryLocation, rate, pickupLocation, pickupDate } = route;
	const dateFormat = new Date(deliveryDate);
	const delivery = deliveryDate ? formatAsLocalDateTime(dateFormat) : "";
	const dateFormatPickup = new Date(pickupDate);
	const pickup = pickupDate ? formatAsLocalDateTime(dateFormatPickup) : "";

	const rateValue = rate && rate.toFixed(2);

	const rowData = {
		id: id,
		broker: broker,
		pickup: getLocation({ city: pickupLocation.city, state: pickupLocation.state }),
		pickupDateFrom: pickup,
		pickupDateTo: pickup,
		delivery: getLocation({ city: deliveryLocation.city, state: deliveryLocation.state }),
		deliveryDateFrom: delivery,
		deliveryDateTo: delivery,
		equipmentType: equipmentType,
		rate: Number(rateValue),
		fromRate: Number(rateValue),
		toRate: Number(rateValue),
		deliveryState: getLocation({ city: deliveryLocation.city, state: deliveryLocation.state }),
		pickupState: getLocation({ city: pickupLocation.city, state: pickupLocation.state }),
		miles: distance,
		loadId: loadId,
		deliveryLocation: getLocation({ city: deliveryLocation.city, state: deliveryLocation.state }),
		pickupLocation: getLocation({ city: pickupLocation.city, state: pickupLocation.state }),
		deliveryLat: deliveryLocation.latitude,
		deliveryLng: deliveryLocation.longitude,
		pickupLat: pickupLocation.latitude,
		pickupLng: pickupLocation.longitude,
	};

	return rowData
};

export const localIdleResponseToRows = (response: idleEquipmentRouteResponseType): localIdleRouteType[] => {
	return response.content.map((row) => localIdleToRow(row))
};


export const ExternalFilterWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<Box sx={{ pr: '10px', maxWidth: '160px' }}>
			{children}
		</Box>
	)
}

export const mockBrokers = [
	{ id: 498, name: 'Coyote' },
	{ id: 2, name: 'Random broker' }
]

export const localIdleCsvMapper = {
	broker: (value:any) => value.name,
}
